import { arrayOf, shape, string } from 'prop-types'

import Card from './components/Card'

import { Grid } from './styles'

const propTypes = {
  cards: arrayOf(
    shape({
      analyticEvent: shape({
        eventAction: string,
        eventCategory: string,
        eventLabel: string,
        eventName: string,
      }),
      category: string,
      ctaText: string,
      ctaUrl: string,
      text: string,
      title: string,
    }),
  ),
}

const CardsList = ({ cards }) => {
  if (!cards || !cards.length) return null

  return (
    <Grid>
      {cards.map(({ id, ...card }, index) => (
        <Card key={id} index={index} {...card} />
      ))}
    </Grid>
  )
}

CardsList.propTypes = propTypes

export default CardsList
