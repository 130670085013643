import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Cta } from 'components/Cta'

export const Wrapper = styled(Cta)(
  ({ area }) => css`
    grid-area: ${area};
    text-decoration: none;
    min-width: 264px;
    width: 100%;

    ${media.between('2xl', '5xl')} {
      min-width: 230px;
      max-width: 288px;
    }
  `,
)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: ${selectTheme('radius.6xl')};
  padding: ${selectTheme('spacingTop.6xl')};
  padding-bottom: ${selectTheme('spacingBottom.7xl')};
  box-shadow: ${selectTheme('shadow.xs')};
`

export const Header = styled.div`
  flex: 1;
  text-transform: uppercase;
`

export const Body = styled.div`
  margin-top: ${selectTheme('spacingTop.10xl')};
`

export const Footer = styled.div`
  flex: 1;
  margin-top: ${selectTheme('spacingTop.7xl')};
`
