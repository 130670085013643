import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as DefaultContainer } from 'components/Container'
import { Grid as DefaultGrid } from 'components/Grid'

export const Section = styled.section(
  ({ color, theme }) => css`
    background-color: ${selectTheme(`colors.${color}`)({ theme })};
  `,
)

export const Container = styled(DefaultContainer)`
  padding-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('2xl')} {
    padding-top: ${selectTheme('spacingTop.20xl')};
    padding-bottom: ${selectTheme('spacingBottom.20xl')};
  }
`

export const Grid = styled(DefaultGrid)`
  grid-template-rows: auto 1fr;
  grid-template-areas:
    '${'image '.repeat(4)}'
    '${'title '.repeat(4)}'
    '${'cards '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'title '.repeat(4)} . . . .'
      '${'cards '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '. ${'title '.repeat(6)} . . . . .'
      '. ${'cards '.repeat(9)} . .';
  }
`
