export function setArtDirection(desktopImage, mobileImage) {
  if (!desktopImage && !mobileImage) {
    return {}
  }

  return {
    artDirection: [
      {
        ...desktopImage.fluid,
        description: desktopImage.description || mobileImage.description,
        media: '(min-width: 600px)',
        sizes: '(max-width: 1920px)',
      },
      {
        ...mobileImage.fluid,
        description: mobileImage.description || desktopImage.description,
        sizes: '(max-width: 600px)',
      },
    ],
  }
}
