import { object } from 'prop-types'

import { LazyImage } from './styles'

const propTypes = {
  image: object,
}

const Image = ({ image }) => {
  if (!image || !image.artDirection) return null

  return <LazyImage {...image} />
}

Image.propTypes = propTypes

export default Image
