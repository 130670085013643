import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
}

const Title = ({ children }) => {
  if (!children) return null

  return (
    <Typography
      variant={{ _: 'bodyMdMedium', '5xl': 'bodyLgMedium' }}
      color="neutral.90"
    >
      {children}
    </Typography>
  )
}

Title.propTypes = propTypes

export default Title
