import { array, object, oneOf, string } from 'prop-types'

import CardsList from './components/CardsList'
import Image from './components/Image'
import Title from './components/Title'
import Wrapper from './components/Wrapper'
import { setArtDirection } from './utils/setArtDirection'

const propTypes = {
  articles: array,
  backgroundColor: oneOf(['gray', 'dark']),
  imageDesktop: object,
  imageMobile: object,
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const Exponencial = ({
  articles,
  backgroundColor,
  imageDesktop,
  imageMobile,
  title,
  titleHeadingTag,
  ...others
}) => {
  titleHeadingTag = titleHeadingTag || 'h1'
  const image = setArtDirection(imageDesktop, imageMobile)

  return (
    <Wrapper color={backgroundColor} {...others}>
      <Title color={backgroundColor} as={titleHeadingTag}>
        {title}
      </Title>
      <Image image={image} />
      <CardsList cards={articles} />
    </Wrapper>
  )
}

Exponencial.propTypes = propTypes

export default Exponencial
