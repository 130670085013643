import { node, oneOf } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
  color: oneOf(['gray', 'dark']),
}

const Wrapper = ({ children, color, ...others }) => {
  color = color === 'dark' ? 'neutral.90' : 'neutral.10'

  return (
    <Section
      color={color}
      data-testid="section-exponencial-wrapper"
      {...others}
    >
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
