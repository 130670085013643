import { node, oneOf } from 'prop-types'

import { RichText } from './styles'

const propTypes = {
  children: node,
  color: oneOf(['gray', 'dark']),
}

const Title = ({ children, color, ...others }) => {
  color = color === 'dark' ? 'neutral.0' : 'neutral.90'

  return (
    <RichText
      color={color}
      variant={{ _: 'headingSmLight', '5xl': 'headingXlLight' }}
      {...others}
    >
      {children}
    </RichText>
  )
}

Title.propTypes = propTypes

export default Title
