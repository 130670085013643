import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
}

const CtaLabel = ({ children }) => {
  if (!children) return null

  return (
    <Typography variant="bodyMdMedium" color="primary.60">
      {children}
    </Typography>
  )
}

CtaLabel.propTypes = propTypes

export default CtaLabel
