import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage as DefaultLazyImage } from 'components/LazyImage'

export const LazyImage = styled(DefaultLazyImage)`
  grid-area: image;
  margin-bottom: ${selectTheme('spacingBottom.6xl')};

  ${media.down('xl')} {
    margin-left: -${selectTheme('spacingY.5xl')};
    margin-right: -${selectTheme('spacingY.5xl')};
  }

  ${media.up('2xl')} {
    grid-area: 1 / 5 / 3 / 9;
    margin-top: -${selectTheme('spacingTop.20xl')};
    margin-bottom: -${selectTheme('spacingBottom.20xl')};
    overflow: visible !important;

    img {
      width: 50vw !important;
    }
  }

  ${media.up('5xl')} {
    grid-area: 1 / 7 / 3 / 13;
  }

  ${media.up('7xl')} {
    img {
      width: 674px !important;
    }
  }
`
