import { number, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import CtaLabel from './components/CtaLabel'
import PreTitle from './components/PreTitle'
import Title from './components/Title'

import { Body, Container, Footer, Header, Wrapper } from './styles'

const propTypes = {
  category: string,
  ctaText: string,
  ctaUrl: string,
  index: number,
  title: string,
}

const Card = ({ category, ctaText, ctaUrl, index, title }) => {
  const { triggerEvent } = useTriggerEvents()

  const area = `card-${index + 1}`
  const cta = {
    url: ctaUrl,
    openInNewTab: true,
    accessibilityDescription: title,
  }

  const handleClick = () => {
    if (!ctaUrl) return

    triggerEvent({ label: `${index + 1} | ${ctaUrl}`, pageInteraction: false })
  }

  return (
    <Wrapper
      area={area}
      onClick={handleClick}
      {...cta}
      data-testid="component-card"
    >
      <Container>
        <Header>
          <PreTitle>{category}</PreTitle>
        </Header>
        <Body>
          <Title>{title}</Title>
        </Body>
        <Footer>
          <CtaLabel>{ctaText}</CtaLabel>
        </Footer>
      </Container>
    </Wrapper>
  )
}

Card.propTypes = propTypes

export default Card
