import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Grid as DefaultGrid } from 'components/Grid'

export const Grid = styled(DefaultGrid)`
  grid-area: cards;
  z-index: 2;
  margin-top: ${selectTheme('spacingTop.10xl')};

  ${media.down('4xl')} {
    display: flex;
    overflow-x: auto;
    grid-column-gap: 16px;
    margin-left: -${selectTheme('spacingLeft.5xl')};
    margin-right: -${selectTheme('spacingRight.5xl')};
    padding-left: ${selectTheme('spacingLeft.5xl')};
    padding-right: ${selectTheme('spacingRight.5xl')};
    padding-bottom: ${selectTheme('spacingBottom.5xl')};
  }

  ${media.between('2xl', '3xl')} {
    margin-right: -${selectTheme('spacingRight.10xl')};
  }

  ${media.up('5xl')} {
    grid-template-columns: repeat(9, 1fr);
    grid-template-areas: '${'card-1 '.repeat(3)} ${'card-2 '.repeat(
      3,
    )} ${'card-3 '.repeat(3)}';
  }
`
